import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_SECTIONS, CREATE_SECTION } from '../queries'
import {
  useParams
} from "react-router-dom"

import {
  TextField,
  Button

} from '@material-ui/core'

import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const SectionForm = ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [casetext, setText] = useState('')
  const [header, setHeader] = useState('')
  const [preparer, setPreparer] = useState('Jouko Johtaja')
  const [caseid, setCaseid] = useState('')
  const [proposalforadecision, setProposal] = useState('')
  const [x, setX] = useState('')
  const [y, setY] = useState('')
  const [z, setZ] = useState('')
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [postoffice, setPostoffice] = useState('')
  const [country, setCountry] = useState('Suomi')
  const [attachments, setAttachments] = useState('')
  const [sectionmunicipality, setMunicipality] = useState('Pori')
  let miiting=''
  miiting=useParams().id
  const [agendaid, setAgenda] = useState(miiting)
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }
  
  

  //setAgenda(miiting)
  const [ createSection ] = useMutation(CREATE_SECTION, {
    refetchQueries: [  {query: ALL_SECTIONS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

/*
  const handleChange = (event) => {
    setNewComment(event.target.value)
  }

  const addComment = (event) => {
    event.preventDefault()
    createComment({
      content: newComment,
      important: false,
    })

    setNewComment('')
  }
  */
 /* const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setText(event)
  }*/
  const submit = async (event) => {
    event.preventDefault()
    
    createSection({
      variables: { caseid, preparer, header, casetext,proposalforadecision, x, y, z, address, postnumber, postoffice, country, attachments, sectionmunicipality,agendaid }
    })

    setPreparer('')
    setText('')
    setCaseid('')
    setProposal('')
    setY('')
    setX('')
    setAddress('')
    setPostoffice('')
    setPostnumber('')
    setAttachments('')
    setCountry('')
    setMunicipality('')
    setAgenda('')
    setSubmitted(true)
    setHeader('')
  }
  
  if(submitted){
        return(<div>
            <h2>{t('headers.addsection')}</h2>
            {t('common.formok')}
            <br />
        </div>
        )
      }else{
      return (
      <div>
        <h2>{t('headers.addsection')}</h2>

        <form onSubmit={submit}>
          {t('common.preparer')} <TextField
            value={preparer}
            onChange={({ target }) => setPreparer(target.value)}
          /><br /><br />
          {t('common.sectionnumber')} <TextField
            value={caseid}
            onChange={({ target }) => setCaseid(target.value)}
          /><br /><br />
          {t('common.header')} <TextField
            value={header}
            onChange={({ target }) => setHeader(target.value)}
          /><br /><br />
          {t('common.explanation')} <TextField
            value={casetext}
            onChange={({ target }) => setText(target.value)}
          /><br /><br />
          {t('common.proposal')} <TextField
            value={proposalforadecision}
            onChange={({ target }) => setProposal(target.value)}
          /><br /><br />
          X <TextField
            value={x}
            onChange={({ target }) => setX(target.value)}
          /><br /><br />
          Y <TextField
            value={y}
            onChange={({ target }) => setY(target.value)}
          /><br /><br />
          Z <TextField
            value={z}
            onChange={({ target }) => setZ(target.value)}
          /><br /><br />
          {t('common.address')} <TextField
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          /><br /><br />
          {t('common.postnumber')} <TextField
            value={postnumber}
            onChange={({ target }) => setPostnumber(target.value)}
          /><br /><br />
          {t('common.postoffice')} <TextField
            value={postoffice}
            onChange={({ target }) => setPostoffice(target.value)}
          /><br /><br />
          {t('common.country')} <TextField
            value={country}
            onChange={({ target }) => setCountry(target.value)}
          /><br /><br />
          {t('common.attachments')} <TextField
            value={attachments}
            onChange={({ target }) => setAttachments(target.value)}
          /><br /><br />
          {t('common.municipal')} <TextField
            value={sectionmunicipality}
            onChange={({ target }) => setMunicipality(target.value)}
          /><br /><br />
          {t('common.meeting')} <TextField
            value={agendaid}
            onChange={({ target }) => setAgenda(target.value)}
          /><br /><br />
          <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <br /><br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
          <br /><br />
      </form>
      </div>
    )
  }
}

export default SectionForm