import React, { useState}  from 'react'
import { useMutation } from '@apollo/client'
import {
  useParams
} from "react-router-dom"
import { ALL_PROPOSALS, DELETE_PROPOSAL, LOCK_PROPOSAL} from '../queries'
import {
  Button
} from '@material-ui/core'

import {useTranslation} from "react-i18next"

const Proposalsadmin = ({ proposals, sections, setError, votes }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  // eslint-disable-next-line
  const [userid, setUserid] = useState('')  




  let sectionss=''
  sectionss = useParams().id
  let valittu=[]
  if(sections!==undefined){
    valittu = sections.filter(function(el) {
      return el.id===sectionss
    })
  }
  console.log(proposals)
  let valitut = proposals.filter(function(el) {
    return el.sectionid===sectionss
  })
  
  let aanet= votes.filter(function(aa) {
    return aa.sectionid===sectionss
  } )
  let aanetasiat= aanet.map(p => p.proposalid)
  // eslint-disable-next-line
  let maarat = Object.values(aanet.reduce((obj, { proposalid }) => {
    if (obj[proposalid] === undefined)
       obj[proposalid] = { proposalid: proposalid, occurrences: 1 };
    else
       obj[proposalid].occurrences++;
    return obj;
 }, {}));
 /*let maara = maarat.map(o => {
  const sisalto = {};

  sisalto[o.proposalid] = o.occurrences
 return sisalto})
*/
  //Tähän uuden parametrin count lisääminen valitut arrayhin sen arvoksi maarat arraystä saman commentid:n parametrin occurrences arvo
  let uus = valitut.map((item) => 
    Object.assign({}, item, {maara:aanetasiat.filter(function(aa) {
      if(aa === null){
        aa=[]
      }
      return aa===item.id}).length
    }))
  /*for(let i=0;i<valitut.length;i++){
    valitut[i].maara=0
    Object.preventExtensions(valitut)
  }*/
  //const maarat = aanetasiat => aanetasiat.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {})
  console.log(uus)
  console.log(aanetasiat)

  let viranaanet = aanetasiat.filter(function(dd){
    return dd==='1'}
  )
  console.log(viranaanet)

  const [ lockProposal ] = useMutation(LOCK_PROPOSAL, {
    refetchQueries: [  {query: ALL_PROPOSALS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  const [ deleteProposal ] = useMutation(DELETE_PROPOSAL, {
    refetchQueries: [  {query: ALL_PROPOSALS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const setLocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    lockProposal({
      variables: { id:event, locked:true }
    })
    //muutetaan activated !actived edituser
  }
  const setUnlocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    
    lockProposal({
      variables: { id:event, locked:false }
    })
    //muutetaan activated !actived edituser
  }

  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteProposal({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }

  //let proposals =[]
  if(proposals){
    return (
      <div width='100%'>
        <b>{t('headers.alternativeproposals')}</b>
        {uus.map(prop => (
         <>{prop.proposaltext} <br />
        <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setUnlocked(prop.id)}>{t('common.unlock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setLocked(prop.id)}>{t('common.lock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa aloitteen?')){setDelete(prop.id)}}}>{t('common.delete')}</Button>
         </>
         ))}
      </div>
    )
  }else{
    return (
      <li className='proposal'>
         {t('common.noalternativeproposals')}
      </li>
    )
  }
}

export default Proposalsadmin