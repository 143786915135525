import React from 'react'

import {
  useParams
} from "react-router-dom"
import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/

const Minut = ({minute}) => {
  const asia = useParams().id
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  let valittu = minute.filter(function(el) {
    return el.id===asia
  })

  
 let valitut={casetext: 'ladataan...', header:'ladataan', preparer:'ladataan...', caseid:'ladataan...',proposalforadecision:'ladataan...',decision:'ladataan...',x:'ladataan...',y:'ladataan...',z:'ladataan...',address:'ladataan...',postnumber:'ladataan...',postoffice:'ladataan...',country:'ladataan...',attachments:'ladataan...',minutemunicipality:'ladataan...',minutesid:'ladataan...'}
 if(valittu[0]){
  valitut = valittu[0]
  console.log(valitut)
 }
 console.log(valitut)
  /*let valitut={}
  valittu[0]
  console.log(valittu[0])
  */
  return(
    <div width='100%'>
    <table width='100%' align='left'><tr><td align='left' width='50%'>
    <h2>{valitut.header}</h2><br />
    {valitut.casetext}<br /><br />
    <b>{t('common.preparer')}</b> {valitut.preparer}<br /><br />
    <b>{t('common.sectionnumber')}</b> {valitut.caseid}<br /><br />
    <b>{t('common.proposal')}</b> {valitut.proposalforadecision}<br /><br />
    <b>{t('common.decision')}</b> {valitut.decision}<br /><br />
    <b>{t('common.attachments')}</b> {valitut.attachments}<br /><br />
    <b>{t('common.municipal')}</b> {valitut.minutmunicipality}<br /><br />
    <b>{t('common.agenda')}</b> {valitut.minutesid}<br /><br />
    </td></tr></table>
  </div>
)
}

export default Minut