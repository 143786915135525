import { gql  } from '@apollo/client'

const ORGAN_DETAILS = gql`
  fragment OrganDetails on Organ {
    organname
    organshort
    organmunicipality 
    organurl
    domain
    active
    system
  }
`
const LINK_DETAILS = gql`
  fragment LinkDetails on Link {
    linkdatetime
    linktext
    linkdatetime
    linktext
    commentid
    initiativeid
    sectionid
    proposalid
    linktype
    linkref
    userid 
  }
`
const USER_DETAILS = gql`
  fragment UserDetails on User {
    name
    email
    address
    postnumber
    postoffice
    phone
    activated
    type
    datetime
    acceptedtermsofuse
    gender
    born
    municipality
    country
    locked
    code
  }
`
const INITIATIVE_DETAILS = gql`
  fragment InitiativeDetails on Initiative {
    organid
    initiativetext
    initiativedatetime
    initiativeinfo
    initiativeaccepted
    active
  }
`
const VOTING_DETAILS = gql`
  fragment VotingDetails on Voting {
    proposalid
    commentid
    sectionid
    userid
    initiativeid
    votedatetime
    voteinfo
  }
`

const SECTION_DETAILS = gql`
  fragment SectionDetails on Section {
    caseid
    preparer
    header
    casetext
    proposalforadecision
    x
    y
    z
    address
    postnumber
    postoffice
    country
    attachments
    sectionmunicipality
    agendaid
    sectionurl
  }
`
const MINUT_DETAILS = gql`
  fragment MinutDetails on Minut {
    caseid
    preparer
    header
    casetext
    proposalforadecision
    decision
    x
    y
    z
    address
    postnumber
    postoffice
    country
    attachments
    minutmunicipality
    minutesid
    minuturl
  }
`
const COMMENT_DETAILS = gql`
  fragment CommentDetails on Comment {
    commentdatetime
    commenttext
    sectionid 
  }
`
const MEETING_DETAILS = gql`
  fragment MeetingDetails on Meeting {
    meetingdatetime
    meetingtext
    organid
    meetingmunicipality
    agendaurl
  }
`
const MINUTES_DETAILS = gql`
  fragment MinutesDetails on Minutes {
    minutesdatetime
    minutestext
    organid
    minutesmunicipality
    minutesurl
  }
`
const PROPOSAL_DETAILS = gql`
  fragment ProposalDetails on Proposal {
    proposaldatetime
    commenttext
    sectionid
  }
`


export const ALL_LINKS = gql`
query {
  allLinks {
    linkdatetime
    linktext
    commentid
    initiativeid
    sectionid
    proposalid
    linktype
    linkref 
    id
  }
}
`
export const ALL_VOTES = gql`
query {
  allVotes {
    proposalid
    commentid
    sectionid
    linkid
    userid
    initiativeid
    votedatetime
    voteinfo
    id
  }
}
`
export const ALL_USERS = gql`
query {
  allUsers {
    name
    email
    address
    postnumber
    postoffice
    phone
    activated
    type
    datetime
    acceptedtermsofuse
    gender
    born
    municipality
    country
    locked
    passwordhash
    code
    id
  }
}
`
export const ALL_INITIATIVES = gql`
query {
  allInitiatives {  
    organid
    initiativetext
    initiativedatetime
    initiativeinfo
    initiativeaccepted
    active
    id
  }
}
`
 
export const ALL_ORGANS = gql`
  query {
    allOrgans {
      organname
      organshort
      organmunicipality
      organurl
      active
      domain
      system
      id
    }
  }
`
export const ALL_MEETINGS = gql`
  query {
    allMeetings {
      meetingtext
      meetingdatetime
      organid
      meetingmunicipality
      agendaurl
      id
    }
  }
`
export const ALL_MINUTES = gql`
  query {
    allMinutes {
      minutestext
      minutesdatetime
      organid
      minutesmunicipality
      minutesurl
      id
    }
  }
`
export const ALL_MUNICIPALITIES = gql`
  query {
    allMunicipalities {
      organmunicipality
    }
  }
`

export const ALL_SECTIONS = gql`
  query {
    allSections {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      sectionmunicipality
      agendaid
      sectionurl
      id
    }
  }
`
export const ALL_MINUT = gql`
  query {
    allMinut {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      decision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      minutmunicipality
      minutesid
      minuturl
      id
    }
  }
`
export const FIND_ORGAN = gql`
  query findOrganByName($nameToSearch: String!) {
    findOrgan(organname: $nameToSearch) {
      organname
      organshort
      organmunicipality
      organurl
      id
    }
  }
  `
  export const FIND_LINK = gql`
  query findLinkByID($IDToSearch: String!) {
    findLink(id: $IDToSearch) {
      linkdatetime
      linktext
      linkdatetime
      linktext
      commentid
      sectionid
      initiativeid
      proposalid
      linktype
      linkref
      userid 
      id
    }
  }
  `
  
  export const FIND_USER = gql`
  query findUserByEmail($email: String!) {
    findUser(email: $email) {
      name
      email
      address
      postnumber
      postoffice
      phone
      activated
      type
      datetime
      acceptedtermsofuse
      gender
      born
      municipality
      country
      locked
      code
      id
    }
  }
  `
   
  export const FIND_INITIATIVE = gql`
  query findInitiativeByID($idToSearch: String!) {
    findInitiative(id: $idToSearch) {
      organid
      initiativetext
      initiativedatetime
      initiativeinfo
      initiativeaccepted
      active
      userid
      id
    }
  }
  `
  
  export const FIND_VOTE = gql`
  query findVoteByID($IDToSearch: String!) {
    findVote(id: $IDToSearch) {
      proposalid
      commentid
      sectionid
      initiativeid
      linkid
      userid
      votedatetime
      voteinfo
      id
    }
  }
  `
  

  export const FIND_COMMENT = gql`
  query findCommentByDatetime($datetimeToSearch: String!) {
    findComment(commentdatetime: $datetimeToSearch) {
      commentdatetime
      commenttext
      sectionid
      id
    }
  }
  `
  export const GET_USERPASSCODE = gql`
  query getUserpasscode($email: String!) {
    getUserpasscode(email: $email) {
      message
    }
  }
  `
  export const FIND_SECTION = gql`
  query findSectionByID($IDToSearch: String!) {
    findSection(id: $IDToSearch) {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      sectionmunicipality
      agendaid
      sectionurl
      id
    }
  }
  `
  export const FIND_MINUT = gql`
  query findMinutByID($IDToSearch: String!) {
    findMinut(id: $IDToSearch) {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      decision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      minutmunicipality
      minutesid
      minuturl
      id
    }
  }
  `

  export const FIND_MEETING = gql`
  query findMeetingByDatetime($datetimeToSearch: String!) {
    findMeeting(meetingdatetime: $datetimeToSearch) {
      meetingtext
      meetingdatetime
      organid
      meetingmunicipality
      agendaurl
      id
    }
  }
  `
  export const FIND_MINUTES = gql`
  query findMinutesByDatetime($datetimeToSearch: String!) {
    findMinutes(minutesdatetime: $datetimeToSearch) {
      minutestext
      minutesdatetime
      organid
      minutesmunicipality
      minutesurl
      id
    }
  }
  `
  export const FIND_PROPOSAL = gql`
  query findProposalByDatetime($datetimeToSearch: String!) {
    findProposal(proposaldatetime: $datetimeToSearch) {
      proposaldatetime
      proposaltext
      sectionid
      id
    }
  }
  `
  export const FIND_MUNICIPAL = gql`
  query findMunicipalByName($nameToSearch: String!) {
    findMunicipal(municipalname: $nameToSearch) {
      name
      id
    }
  }
`


export const CREATE_VOTING = gql`
mutation createVoting($initiativeid: String, $linkid: String, $sectionid: String, $proposalid: String, $commentid: String, $userid: String, $votedatetime: String!, $voteinfo: String) {
  addVote(
    proposalid: $proposalid,
    commentid: $commentid,
    userid: $userid,
    sectionid: $sectionid,
    linkid: $linkid,
    votedatetime: $votedatetime,
    voteinfo: $voteinfo,
    initiativeid: $initiativeid
  ) {
    proposalid
    commentid
    userid
    sectionid
    linkid
    votedatetime
    voteinfo
    initiativeid
    id
  }
}
`
export const CREATE_USER = gql`
mutation addUser($name: String!, $email: String!, $code: String, $locked: Boolean, $passwordhash: String!, $address: String, $gender: String,$born: String, $municipality: String, $country: String, $acceptedtermsofuse: String, $postnumber:String!, $postoffice: String!, $phone: String, $activated: Boolean, $type: String, $datetime: String) {
  addUser(
    name: $name,
    email: $email ,
    passwordhash: $passwordhash,
    address: $address ,
    postnumber: $postnumber ,
    postoffice: $postoffice ,
    phone: $phone,
    activated: $activated,
    type: $type,
    datetime: $datetime,
    acceptedtermsofuse: $acceptedtermsofuse,
    born: $born,
    gender: $gender,
    municipality: $municipality,
    country: $country,
    locked: $locked,
    code: $code
  ) {
    name
    email
    address
    postnumber
    postoffice
    phone
    activated
    type
    datetime
    acceptedtermsofuse
    born
    municipality
    country
    gender
    locked
    code
    id
  }
}
`
export const CREATE_INITIATIVE = gql`
mutation createInitiative($organid: String, $initiativetext: String!, $initiativedatetime: String!, $initiativeinfo: String, $initiativeaccepted: String!, $active: String) {
  addInitiative(
    organid: $organid,
    initiativetext: $initiativetext
    initiativedatetime: $initiativedatetime
    initiativeinfo: $initiativeinfo
    initiativeaccepted: $initiativeaccepted
    active: $active
  ) {
    organid
    initiativetext
    initiativedatetime
    initiativeinfo
    initiativeaccepted
    active
    id
  }
}
`

export const CREATE_ORGAN = gql`
  mutation createOrgan($organname: String!, $organshort: String!, $organmunicipality: String!, $organurl: String, $system: String, $domain: String) {
    addOrgan(
      organname: $organname,
      organshort: $organshort,
      organmunicipality: $organmunicipality,
      organurl: $organurl,
      system: $system,
      domain: $domain
    ) {
      organname
      organshort
      organmunicipality
      organurl
      system
      domain
      id
    }
  }
`

export const CREATE_COMMENT = gql`
  mutation createComment($commenttext: String!, $commentdatetime: String!, $sectionid: String!) {
    addComment(
      commenttext: $commenttext,
      commentdatetime: $commentdatetime,
      sectionid: $sectionid
    ) {
      commenttext
      commentdatetime
      sectionid
      id
    }
  }
`
export const CREATE_LINK = gql`
  mutation createLink($sectionid: String!, $linktext: String!, $linkdatetime: String!, $initiativeid:String, $linktype: String, $linkref: String!, $proposalid: String, $commentid: String) {
    addLink(
      linktext: $linktext
      linkdatetime: $linkdatetime
      commentid: $commentid
      sectionid: $sectionid
      initiativeid: $initiativeid
      proposalid: $proposalid
      linktype: $linktype
      linkref: $linkref
    ) {
      linktext
      linkdatetime
      commentid
      sectionid
      initiativeid
      proposalid
      linktype
      linkref
      id
    }
  }
`

export const CREATE_SECTION = gql`
  mutation createSection($caseid: String, $header: String!, $preparer: String, $sectionurl: String, $casetext: String!, $proposalforadecision: String!, $x: String, $y: String, $z: String, $address: String, $postnumber: String, $postoffice: String, $country: String, $attachments: String, $sectionmunicipality: String, $agendaid: String) {
    addSection(
      caseid: $caseid
      preparer: $preparer
      header: $header
      casetext: $casetext 
      proposalforadecision: $proposalforadecision
      x: $x
      y: $y
      z: $z
      address: $address
      postnumber: $postnumber
      postoffice: $postoffice
      country: $country
      attachments: $attachments
      sectionmunicipality: $sectionmunicipality
      agendaid: $agendaid
      sectionurl: $sectionurl
    ) {
      caseid
      preparer
      casetext
      proposalforadecision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      sectionmunicipality
      agendaid
      sectionurl
      id
    }
  }
`
export const CREATE_MEETING = gql`
  mutation createMeeting($meetingtext: String!, $agendaurl: String, $meetingdatetime: String!, $organid: String!, $meetingmunicipality: String! ) {
    addMeeting(
      meetingtext: $meetingtext,
      meetingdatetime: $meetingdatetime,
      organid: $organid
      meetingmunicipality: $meetingmunicipality
      agendaurl: $agendaurl
    ) {
      meetingtext
      meetingdatetime
      organid
      meetingmunicipality
      agendaurl
      id
    }
  }

`
export const CREATE_MINUT = gql`
  mutation createMinut($caseid: String, $header: String!, $preparer: String, $minuturl: String, $casetext: String, $proposalforadecision: String, $decision: String, $x: String, $y: String, $z: String, $address: String, $postnumber: String, $postoffice: String, $country: String, $attachments: String, $minutmunicipality: String, $minutesid: String) {
    addMinut(
      caseid: $caseid
      preparer: $preparer
      header: $header
      casetext: $casetext 
      proposalforadecision: $proposalforadecision
      decision: $decision
      x: $x
      y: $y
      z: $z
      address: $address
      postnumber: $postnumber
      postoffice: $postoffice
      country: $country
      attachments: $attachments
      minutmunicipality: $minutmunicipality
      minutesid: $minutesid
      minuturl: $minuturl
    ) {
      caseid
      preparer
      casetext
      proposalforadecision
      decision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      minutmunicipality
      minutesid
      minuturl
      id
    }
  }
`
export const CREATE_MINUTES= gql`
  mutation createMinutes($minutestext: String, $minutesurl: String, $minutesdatetime: String, $organid: String, $minutesmunicipality: String ) {
    addMinutes(
      minutestext: $minutestext,
      minutesdatetime: $minutesdatetime,
      organid: $organid,
      minutesmunicipality: $minutesmunicipality,
      minutesurl: $minutesurl
    ) {
      minutestext
      minutesdatetime
      organid
      minutesmunicipality
      minutesurl
      id
    }
  }

`

export const CREATE_PROPOSAL = gql`
  mutation createProposal($proposaltext: String!, $proposaldatetime: String!, $sectionid: String) {
    addProposal(
      proposaltext: $proposaltext,
      proposaldatetime: $proposaldatetime,
      sectionid: $sectionid
    ) {
      proposaltext
      proposaldatetime
      sectionid
      id
    }
  }

`
export const CREATE_PERSON = gql`
  mutation createPerson($name: String!, $street: String!, $city: String!, $phone: String) {
    addPerson(
      name: $name,
      street: $street,
      city: $city,
      phone: $phone
    ) {
      name
      phone
      id
      street
      city
    }
  }
`
export const ALL_PERSONS = gql`
  query {
    allPersons  {
      name
      phone
      id
    }
  }
`
export const ALL_COMMENTS = gql`
  query {
    allComments  {
      commenttext
      commentdatetime
      sectionid
      id
    }
  }
`


export const ALL_PROPOSALS = gql`
  query {
    allProposals  {
      proposaltext
      proposaldatetime
      sectionid
      id
    }
  }
`

export const GET_SCRAPING = gql`
  query getScraping($url: String!, $system: String){
    getScraping(url: $url, system: $system)  {
      message
    }
  }
`
export const GET_SCRAPINGPOST = gql`
  query getScrapingpost($url: String!, $system: String){
    getScrapingpost(url: $url, system: $system)  {
      message
    }
  }
`
export const GET_SCRAPINGPDF = gql`
  query getScrapingpdf($url: String!, $system: String){
    getScrapingpdf(url: $url, system: $system)  {
      message
    }
  }
`
export const GET_PIC = gql`
  query getPic($url: String!){
    getPic(url: $url)  {
      message
    }
  }
`
export const EDIT_MEETING = gql`
  mutation editMeeting($meetingdatetime: String!, $meetingtext: String!) {
    editMeeting(meetingdatetime: $meetingdatetime, meetingtext: $meetingtext)  {
      meetingdatetime
      meetingtext
      meetingmunicipality
      agendaurl
      id
    }
  }
`
export const EDIT_MINUTES = gql`
  mutation editMinutes($minutesdatetime: String, $minutestext: String) {
    editMinutes(minutesdatetime: $minutesdatetime, minutestext: $minutestext)  {
      minutesdatetime
      minutestext
      minutesmunicipality
      minutesurl
      id
    }
  }
`
export const EDIT_USER = gql`
mutation editUser($name: String!, $email: String!, $code: String, $locked: Boolean, $passwordhash: String!, $address: String, $gender: String,$born: String, $municipality: String, $country: String, $postnumber:String!, $postoffice: String!, $phone: String, $activated: Boolean, $type: String, $datetime: String)  {
  editUser(
    name: $name,
    email: $email ,
    passwordhash: $passwordhash,
    address: $address ,
    postnumber: $postnumber ,
    postoffice: $postoffice ,
    phone: $phone,
    activated: $activated,
    type: $type,
    datetime: $datetime,
    born: $born,
    gender: $gender,
    municipality: $municipality,
    country: $country,
    locked: $locked,
    code: $code
  ) {
    name
    email
    address
    postnumber
    postoffice
    phone
    activated
    type
    datetime
    born
    municipality
    country
    gender
    locked
    code
    id
  }
}
`
export const ACTIVATE_USER = gql`
mutation activateUser($activated: Boolean!, $email: String!, $acceptedtermsofuse: String)  {
  activateUser(
    activated: $activated,
    email: $email,
    acceptedtermsofuse: $acceptedtermsofuse
  ) {
    email
    activated
    id
  }
}
`
export const LOCK_USER = gql`
mutation lockUser($locked: Boolean!, $email: String!)  {
  lockUser(
    locked: $locked,
    email: $email
  ) {
    email
    locked
    id
  }
}
`
export const LOCK_COMMENT = gql`
mutation lockComment($locked: Boolean!, $id: String!)  {
  lockComment(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_INITIATIVE = gql`
mutation lockInitiative($locked: Boolean!, $id: String!)  {
  lockInitiative(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_PROPOSAL = gql`
mutation lockProposal($locked: Boolean!, $id: String!)  {
  lockProposal(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_MEETING = gql`
mutation lockMeeting($locked: Boolean!, $id: String!)  {
  lockMeeting(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_SECTION = gql`
mutation lockSection($locked: Boolean!, $id: String!)  {
  lockSection(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_MINUTES = gql`
mutation lockMinutes($locked: Boolean!, $id: String!)  {
  lockMinutes(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_MINUT = gql`
mutation lockMinut($locked: Boolean!, $id: String!)  {
  lockMinut(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_ORGAN = gql`
mutation lockOrgan($locked: Boolean!, $id: String!)  {
  lockOrgan(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`
export const LOCK_LINK = gql`
mutation lockLink($locked: Boolean!, $id: String!)  {
  lockLink(
    locked: $locked,
    id: $id
  ) {
    locked
    id
  }
}
`

export const EDIT_PASSWORD = gql`
mutation editPassword($email: String!, $passwordhash: String!)  {
  editPassword(
    email: $email,
    passwordhash: $passwordhash
  ) {
    email
    id
  }
}
`
export const SAVE_CODE = gql`
mutation saveCode($email: String!, $code: String!)  {
  saveCode(
    email: $email,
    code: $code
  ) {
    message
  }
}
`
export const EDIT_VOTE = gql`
  mutation editVote($proposalid: String,$sectionid: String, $commentid: String, $userid: String, $votedatetime: String!, $voteinfo: String, $initiativeid: String) {
    editVote(proposalid: $proposalid,sectionid: $sectionid, commentid: $commentid, userid: $userid, votedatetime: $votedatetime, voteinfo: $voteinfo, initiativeid: $initiativeid)  {
      proposalid
      commentid
      sectionid
      userid
      initiativeid
      votedatetime
      voteinfo
      id
    }
  }
`

export const EDIT_INITIATIVE = gql`
mutation editInitiative($organid: String, $initiativetext: String, $initiativedatetime: String,$initiativeinfo: String, $initiativeaccepted: String!, $active: String) {
  editInitiative(
    organid: $organid,
    initiativetext: $initiativetext
    initiativedatetime: $initiativedatetime
    initiativeinfo: $initiativeinfo
    initiativeaccepted: $initiativeaccepted
    active: $active
  ) 
}
`

export const EDIT_ORGAN = gql`
  mutation editOrgan($organname: String!, $organshort: String!, $organmunicipality: String!, $organurl: String, $system: String, $active: Boolean, $domain: String) {
    editOrgan(
      organname: $organname,
      organshort: $organshort,
      organmunicipality: $organmunicipality,
      organurl: $organurl,
      system: $system,
      domain: $domain,
      active: $active
    ) {
      organname
      organshort
      organmunicipality
      organurl
      system
      domain 
      active
      id
    }
  }
`


export const EDIT_SECTION = gql`
  mutation editSection($caseid: String, $header: String!, $preparer: String!, $sectionurl: String, $casetext: String!, $proposalforadecision: String!, $x: String, $y: String, $z: String, $address: String, $postnumber: String, $postoffice: String, $country: String, $attachments: String, $sectionmunicipality: String, $agendaid: String) {
    editSection(caseid: $caseid, header: $header, preparer: $preparer, sectionurl: $sectionurl, casetext: $casetext, proposalforadecision: $proposalforadecision, x: $x, y: $y, z: $z, address: $address, postnumber: $postnumber, postoffice: $postoffice, country: $country, attachments: $attachments, sectionmunicipality: $sectionmunicipality, agendaid: $agendaid)  {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      sectionmunicipality
      agendaid
      sectionurl
      id
    }
  }
`
export const EDIT_MINUT = gql`
  mutation editMinut($caseid: String, $header: String!, $preparer: String!, $sectionurl: String, $casetext: String, $proposalforadecision: String,$decision: String, $x: String, $y: String, $z: String, $address: String, $postnumber: String, $postoffice: String, $country: String, $attachments: String, $minutmunicipality: String, $minutesid: String) {
    editMinut(caseid: $caseid, header: $header, preparer: $preparer, minuturl: $minuturl, casetext: $casetext, proposalforadecision: $proposalforadecision,decision: $decision, x: $x, y: $y, z: $z, address: $address, postnumber: $postnumber, postoffice: $postoffice, country: $country, attachments: $attachments, minutmunicipality: $minutmunicipality, minutesid: $minutesid)  {
      caseid
      preparer
      header
      casetext
      proposalforadecision
      decision
      x
      y
      z
      address
      postnumber
      postoffice
      country
      attachments
      minutmunicipality
      minutesid
      minuturl
      id
    }
  }
`
export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id)  {
      name
    }
  }
`
export const DELETE_ORGAN = gql`
  mutation deleteOrgan($id: String!) {
    deleteOrgan(id: $id)  {
      organname
    }
  }
`
export const DELETE_COMMENT = gql`
  mutation deleteComment($id: String!) {
    deleteComment(id: $id)  {
      commenttext
    }
  }
`
export const DELETE_LINK = gql`
  mutation deleteLink($id: String!) {
    deleteLink(id: $id)  {
      linktext
    }
  }
`
export const DELETE_INITIATIVE = gql`
  mutation deleteInitiative($id: String!) {
    deleteInitiative(id: $id)  {
      text
    }
  }
`
export const DELETE_PROPOSAL = gql`
  mutation deleteProposal($id: String!) {
    deleteProposal(id: $id)  {
      text
    }
  }
`
export const DELETE_SECTION = gql`
  mutation deleteSection($id: String!) {
    deleteSection(id: $id)  {
      casetext
    }
  }
`
export const DELETE_MEETING = gql`
  mutation deleteMeeting($id: String!) {
    deleteMeeting(id: $id)  {
      meetingtext
    }
  }
`
export const DELETE_MINUT = gql`
  mutation deleteMinut($id: String!) {
    deleteMinut(id: $id)  {
      casetext
    }
  }
`
export const DELETE_MINUTES = gql`
  mutation deleteMinutes($id: String!) {
    deleteMinutes(id: $id)  {
      minutestext
    }
  }
`
export const LOGIN = gql`
  mutation login($email: String!, $passwordhash: String!) {
    login(email: $email, passwordhash: $passwordhash)  {
      value
    }
  }
`
export const SEND_EMAIL = gql`
  mutation sendEmail($to: String!, $header: String!, $text: String!, $code: String) {
    sendEmail(to: $to, header: $header, text: $text, code: $code)  {
      message
    }
  }
`
export const GET_ACTIVATIONCODE = gql`
  query getActivationcode($email: String!) {
    getActivationcode(email: $email)  {
      message
    }
  }
`
export const DECRYPT_CODE = gql`
  query decryptCode($code: String!) {
    decryptCode(code: $code)  {
      message
    }
  }
`

export const ORGAN_ADDED = gql`
  subscription {
    organAdded {
      ...OrganDetails
    }
  }
  ${ORGAN_DETAILS}
`
export const LINK_ADDED = gql`
  subscription {
    linkAdded {
      ...LinkDetails
    }
  }
  ${LINK_DETAILS}
`

export const COMMENT_ADDED = gql`
  subscription {
    commentAdded {
      ...CommentDetails
    }
  }
  ${COMMENT_DETAILS}
`
export const VOTE_ADDED = gql`
  subscription {
    voteAdded {
      ...VoteDetails
    }
  }
  ${VOTING_DETAILS}
`

export const INITIATIVE_ADDED = gql`
  subscription {
    initiativeAdded {
      ...InitiativeDetails
    }
  }
  ${INITIATIVE_DETAILS}
`

export const MEETING_ADDED = gql`
  subscription {
    meetingAdded {
      ...MeetingDetails
    }
  }
  ${MEETING_DETAILS}
`

export const MINUTES_ADDED = gql`
  subscription {
    minutesAdded {
      ...MinutesDetails
    }
  }
  ${MINUTES_DETAILS}
`
export const USER_ADDED = gql`
  subscription {
    userAdded {
      ...UserDetails
    }
  }
  ${USER_DETAILS}
`
export const PROPOSAL_ADDED = gql`
  subscription {
    proposalAdded {
      ...ProposalDetails
    }
  }
  ${PROPOSAL_DETAILS}
`
export const SECTION_ADDED = gql`
  subscription {
    sectionAdded {
      ...SectionDetails
    }
  }
  ${SECTION_DETAILS}
`
export const MINUT_ADDED = gql`
  subscription {
    minutAdded {
      ...MinutDetails
    }
  }
  ${MINUT_DETAILS}
`