import React, { useState, useEffect } from 'react'
import { FIND_MUNICIPAL, ALL_MUNICIPALITIES } from '../queries'
import { useQuery, useApolloClient, useSubscription } from '@apollo/client'

import {
  Link
} from "react-router-dom"

import {
  Button
} from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'


const Municipalities = ({municipalities, munic}) => {
  // eslint-disable-next-line
  const [municipal, setMunicipal] = useState(null)
  // eslint-disable-next-line
  const [getMunicipal, result] = useLazyQuery(FIND_MUNICIPAL)

  /*const showMunicipal = (name) => {
    getMunicipal({ variables: { nameToSearch: name } })
  }
*/
const organsw = useQuery(ALL_MUNICIPALITIES, {
  pollInterval: 10000  })

let organss=[]
if(organsw.data){
  organss=organsw.data.allMunicipalities
  //console.log(organss)
}

  useEffect(() => {
    if (result.data) {
      setMunicipal(result.data.findMunicipal)
    }
  }, [result.data])

  //console.log(municipalities)
  let muni = municipalities.map(mu => (
    mu.organmunicipality
  ))
  let unique = [...new Set(muni)]
  //console.log(unique)
  return(
    <div>
      {unique.sort().map(mun => ( 
            <Button variant="contained" size='normal' color="inherit" component={Link} to={`/organs/${mun}`}>
            {mun}
          </Button>
      ))}
    </div>
 
  )
}
export default Municipalities