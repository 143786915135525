import React, { useState, useEffect } from 'react'
import { FIND_USER, ALL_USERS, EDIT_USER } from '../queries'


import {
  TextField,
  Button

} from '@material-ui/core'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
const User = ({ createComment,user, setError}) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [newComment, setNewComment] = useState('')
  const [useri, setUser] = useState(null)
  const [getUser, result] = useLazyQuery(FIND_USER)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  //const [activated, setActivated] = useState('')
  // eslint-disable-next-line
  const [datetime, setDatetime] = useState('')
  //const [type, setType] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [postoffice, setPostoffice] = useState('')
  const [password, setPassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [gender, setGender] = useState('')
  const [born, setBorn] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [country, setCountry] = useState('')
  const [locked, setLocked] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [getPerson, resultt] = useLazyQuery(FIND_USER)

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }
  
  const bcryptjs = require('bcryptjs')
  
  /*const showUser = (user) => {
    getUser({ variables: { email: user } })
  }*/
  useEffect(() => {
    getPerson({ 
      variables: { email: user } 
    })
  }, [getPerson, user])
  
  



  /**showUser(user)
  */
  const handleChange = (event) => {
    setNewComment(event.target.value)
  }
  const [ editUseri ] = useMutation(EDIT_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      setError('virhe')
      //error.graphQLErrors[0].message
      console.log(error)
    }
  })

  
  useEffect(() => {
    if (resultt.data) {  
      //setUsertype(resultt.data.findUser['type'])
      console.log(resultt.data)
      localStorage.setItem('usertype', resultt.data.findUser['type'])
      setName(resultt.data.findUser.name)
      setPhone(resultt.data.findUser.phone)
      setEmail(resultt.data.findUser.email)
      setAddress(resultt.data.findUser.address)
      setPostoffice(resultt.data.findUser.postoffice)
      setPostnumber(resultt.data.findUser.postnumber)
      setMunicipality(resultt.data.findUser.municipality)
      setLocked(resultt.data.findUser.locked)
    }
    // eslint-disable-next-line
  }, [resultt])
  const editUser = async (event) => {
    event.preventDefault()
    if(password===repassword){
      //const saltRounds = 17
      var salt = bcryptjs.genSaltSync(12)
      const passwordhash = await bcryptjs.hash(password, salt)
      editUseri({ variables: { name, email, passwordhash, address, postnumber, postoffice, datetime, phone, activated: false, type:'1'  }
      })

      setNewComment('')
      setSubmitted(true)
    }
  }
  if(useri){
    /*setName(useri.name)
    setPhone(useri.phone)
    setEmail(useri.email)
    setPostnumber(useri.postnumber)
    setPostoffice(useri.postoffice)*/
    return (
      <div>
        <h2>{t('headers.owninfo')}</h2> 
        <form onSubmit={editUser}>
        {t('common.name')} <TextField value={name} onChange={({ target }) => setName(target.value)} /><br />
        {t('common.phone')} <TextField value={phone} onChange={({ target }) => setPhone(target.value)} /><br />
        {t('common.email')} <TextField value={email} onChange={({ target }) => setEmail(target.value)} /><br />
        {t('common.postnumber')} <TextField value={address} onChange={({ target }) => setAddress(target.value)} /><br />
        {t('common.postnumber')} <TextField value={postnumber} onChange={({ target }) => setPostnumber(target.value)} /><br />
        {t('common.postoffice')} <TextField value={postoffice} onChange={({ target }) => setPostoffice(target.value)} /><br />
        {t('common.newpassword')} <TextField value={newpassword}  type='password' onChange={({ target }) => setNewpassword(target.value)}/><br />
          {t('common.repassword')} <TextField value={repassword} type='password' onChange={({ target }) => setRepassword(target.value)}/><br />
          {t('common.currentpassword')} <TextField value={password} type='password' onChange={({ target }) => setPassword(target.value)} /><br />
          {t('common.born')} <TextField value={password} onChange={({ target }) => setBorn(target.value)} /><br />
          {t('common.gender')} <TextField value={password} onChange={({ target }) => setGender(target.value)} /><br />
          {t('common.municipality')} <TextField value={password} onChange={({ target }) => setMunicipality(target.value)} /><br />
          {t('common.country')} <TextField value={password} onChange={({ target }) => setCountry(target.value)} /><br />
          {t('common.locked')} <TextField value={locked} onChange={({ target }) => setLocked(target.value)} /><br />
          
          <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <br /><br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.save')}</Button>
          <br /><br />
      </form> 
      </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.owninfo')}</h2> 
        <form onSubmit={editUser}>
        {t('common.name')} <TextField value={name} /><br />
        {t('common.phone')} <TextField value={phone} /><br />
        {t('common.email')} <TextField value={email} /><br />
        {t('common.municipal')} <TextField value={municipality} /><br />
          {t('common.locked')} <TextField value={locked} onChange={({ target }) => setLocked(target.value)} /><br />
          <Button variant="contained" style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type="submit">{t('common.save')}</Button>
        </form> 
        <br />
        <Link
          {...{
            component: RouterLink,
            to: '/forgetpassword',
            style: { textDecoration: "none" },
          }}
        >
          {t('common.forgetpassword')}
          </Link>
        
      </div>
    )
  }
}
export default User