import React, { Component, useState, useEffect } from "react"
import { useLazyQuery, useQuery } from '@apollo/client'
import rp from "request-promise"
import {useTranslation} from "react-i18next"
import {
  Button
} from '@material-ui/core'
import {
  Link
} from "react-router-dom"
import { FIND_ORGAN, GET_SCRAPING } from '../queries'
const _ = require('lodash')
const axios = require("axios")
//const rp = require('request-promise')
const cheerio= require('cheerio')
//const puppeteer = require('puppeteer')
const readline = require('readline')
const request = require('request')
var phantom = require("phantom")
var _ph, _page, _outObj;


const Admin = ({error}) => {

  return(
    <div>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/scraping"> 
        Listojen lukeminen
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/minutesscraping"> 
        Pöytäkirjojen lukeminen
      </Button>
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/minutesadmin"> 
        Pöytäkirjat
      </Button>
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/minuteadmin"> 
        Pöytäkirjojen asiat
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/users"> 
        Käyttäjät
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/organsadmin"> 
      Toimielimet
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/meetingsadmin"> 
      Kokoukset
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/commentsadmin"> 
      Kommentit
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/initiativesadmin"> 
      Aloitteet
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/proposalsadmin"> 
      Päätösehdotukset
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/linksadmin"> 
      Linkit
      </Button>
      <br /><br />
      <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/sectionsadmin"> 
      Asiat
      </Button>
      <br />
      <br />  
    </div> 
    )

}

export default Admin