import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_MEETINGS, CREATE_MEETING } from '../queries'

import {useTranslation} from "react-i18next"
import {
  TextField,
  Button
} from '@material-ui/core'
import ReCAPTCHA from "react-google-recaptcha"

const MeetingForm = ({ setError }) => {

  const [meetingtext, setText] = useState('')
  const [meetingdatetime, setDatetime] = useState('werewrwe')
  const [organid, setOrganid] = useState('')
  const [agendaurl, setUrl] = useState('')
  const [meetingmunicipality, setMunicipality] = useState('')
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)


  const [ createMeeting ] = useMutation(CREATE_MEETING, {
    refetchQueries: [  {query: ALL_MEETINGS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

/*
  const handleChange = (event) => {
    setNewComment(event.target.value)
  }

  const addComment = (event) => {
    event.preventDefault()
    createComment({
      content: newComment,
      important: false,
    })

    setNewComment('')
  }
  */
  const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setText(event)
  }

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const submit = async (event) => {
    event.preventDefault()
    
    createMeeting({
      variables: { meetingdatetime, meetingtext, agendaurl, meetingmunicipality, organid: organid.length > 0 ? organid : 'tyhjä' }
    })

    setDatetime('rwerwer')
    setText('')
    setOrganid('')
    setMunicipality('')
    setUrl('')
    setSubmitted(true)
  }
  const stylea = {
    background: 'white',
    opacity: 0.9
  }
  if(submitted){
    return(<div>
        <h2>{t('headers.addmeeting')}</h2>
        {t('common.formok')}
        <br />
    </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.addmeeting')}</h2>

        <form onSubmit={submit}>
          {t('common.time')}<TextField
            value={meetingtext}
            onChange={({ target }) => uusi(target.value)}
          /><br /><br />
          {t('common.organ')}<TextField
            value={organid}
            onChange={({ target }) => setOrganid(target.value)}
          /><br /><br />
          {t('common.municipal')}<TextField
            value={meetingmunicipality}
            onChange={({ target }) => setMunicipality(target.value)}
          /><br /><br />
          Url<TextField
            value={agendaurl}
            onChange={({ target }) => setUrl(target.value)}
          /><br /><br />
          <div align='center'>
                <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
              <br /><br />
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
              disabled={(!verified)}
              executing={executing}
              submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.save')}</Button>
            <br /><br />
            </form>
      </div>
    )
  }
}

export default MeetingForm