import React from 'react'
import {
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import {
  Link
} from "react-router-dom"
const Footer = () => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const footerStyle = {
    color: 'grey',
    fontStyle: 'italic',
    fontSize: 16,
    opacity: 0.9,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%'
  }
 

  return (
    <div style={footerStyle}>
    <table bgcolor='#07575B' width='100%'>
      <tbody>
      <tr><td width='20%'><Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/gdpr"> 
      {t('footer.first')}
          </Button></td>
          <td width='20%'><Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/termsofuse">
          {t('footer.second')}
          </Button></td>
          <td width='20%'><Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/instructions">
          {t('footer.third')}
          </Button></td>
          <td width='20%'><Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' color="primary" component={Link} to="/faq">
          {t('footer.fourth')}
          </Button></td>
          <td width='20%'> <font size='2' >
          <c-info v="2" ct="0" pb="Osmo Leppäniemi" ca="2" ma="1" na="Osmo Leppäniemi" cb="2" mb="2" nb="Osmo Leppäniemi" cc="2" mc="" nc="Osmo Leppäniemi" tr="6" cr="" vr="4" sr="4" tl="" tc="CC BY-NC-SA" vc="CC BY-NC-SA" sc="CC BY-NC-SA" sl="" dl="fi" es="0" ep="1" cp="1" cl="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Im9zbW8ubGVwcGFuaWVtaUB0dW5pLmZpIn0"></c-info>
          <script src="https://www.c-info.fi/c-info.js"></script>
         &copy; Osmo Leppäniemi
         </font>
         </td></tr>
          </tbody>
    </table>
    </div>
  )
}

export default Footer