import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Link
} from "react-router-dom"

import {
  Button,
  TextField,
  Checkbox,
  MenuItem,
  Select
} from '@material-ui/core'
import { FIND_ORGAN, EDIT_ORGAN, ALL_ORGANS, DELETE_ORGAN } from '../queries'
import {useTranslation} from "react-i18next"

const Organsadmin = ({ organs, setError }) => {
  const [id, setId] = useState(null)
  const [organ, setOrgan] = useState(null)
  const [organname, setName] = useState('')
  const [organshort, setShort] = useState('')
  const [organurl, setUrl] = useState('')
  const [organmunicipality, setMunicipality] = useState('')
  const [system, setSystem] = useState('')
  const [active, setActive] = useState(true)
  const [domain, setDomain] = useState('')
  // eslint-disable-next-line
  const [getOrgan, result] = useLazyQuery(FIND_ORGAN)
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  /*const showOrgan = (name) => {
    getOrgan({ variables: { nameToSearch: name } })
  }
*/
  const [ editOrgan ] = useMutation(EDIT_ORGAN, {
    refetchQueries: [  {query: ALL_ORGANS} ],
    onError: (error) => {
      setError('virhe')
      //error.graphQLErrors[0].message
      console.log(error)
    }
  })
  const [ deleteOrgan ] = useMutation(DELETE_ORGAN, {
    refetchQueries: [  {query: ALL_ORGANS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  useEffect(() => {
    if (result.data) {
      setOrgan(result.data.findOrgan)
    }
  }, [result.data])

  const submit = async (event) => {
    event.preventDefault()
    console.log(event.target.organshort.value)
    editOrgan({
      variables: { organname:event.target.organname.value, organshort:event.target.organshort.value, organmunicipality:event.target.organmunicipality.value, organurl:event.target.organurl.value, system:event.target.system.value, domain:event.target.domain.value }
    })
  }
  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteOrgan({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }
//<div style={stylea}>
  if (organ) {
    return(
      <div>
        <h2>{organ.organmunicipality}</h2>
        <div>{organ.organsname} {organ.organshort}</div>
        <Button onClick={() => setOrgan(null)}>{t('common.close')}</Button>
      </div>
    )
  }
  
  return (
    <div align='left'>
      <h2>{t('headers.organs')}</h2>
      {organs.map(p =>
        <div>
          <form onSubmit={submit}>
                {t('common.name')} <TextField name='organname'
              value={p.organname}
              onChange={({ target }) => setName(target.value)}
            /><br />
            {t('common.abbreviation')}<TextField name='organshort'
              value={p.organshort}
              onChange={({ target }) => setShort(target.value)}
            /><br />
            {t('common.municipal')} <TextField name='organmunicipality'
              value={p.organmunicipality}
              onChange={({ target }) => setMunicipality(target.value)}
            /><br />
            Url <TextField name='organurl'
              value={p.organurl}
              onChange={({ target }) => setUrl(target.value)}
            /><br />
            Domain <TextField name='domain'
              value={p.domain}
              onChange={({ target }) => setDomain(target.value)}
            /><br />
            System <Select name='system' id="system" label="System" value={p.system} select> 
              <MenuItem value="CaseM">CaseM</MenuItem>
              <MenuItem value="Dynasty">Dynasty</MenuItem>
              <MenuItem value="Tweb">Tweb</MenuItem>
            </Select><br />
            Active <Checkbox name='active'checked={active}
              onChange={({ target }) => setActive(!active)} />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit' >
            {t('common.save')}</Button>
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa toimielimen?')){setDelete(p.id)}}}>{t('common.delete')}</Button>
          <br /><br />
          </form>
       </div> 
      )}

    </div>
  )
}

export default Organsadmin