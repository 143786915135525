import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_USERS, CREATE_USER } from '../queries'
import {
  useParams
} from "react-router-dom"

import {
  TextField,
  Button
} from '@material-ui/core'

import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
import ReCAPTCHA from "react-google-recaptcha"

const UserForm = ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [postoffice, setPostoffice] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [activated, setActivated] = useState(false)
  const [datetime, setDatetime] = useState('')
  const [type, setType] = useState('')
  const [gender, setGender] = useState('')
  const [born, setBorn] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [country, setCountry] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [acceptedtermsofuse, setAccepted] = useState('Sat Feb 27 2021 09:21:48 GMT+0200 (Itä-Euroopan normaaliaika)')
  const [code, setCode] = useState('ddSDSFr434')

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const bcryptjs = require('bcryptjs')

  const [ createUser ] = useMutation(CREATE_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  // eslint-disable-next-line
  let userss=''
  userss = useParams().id
  const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setName(event)
    //setUser(sectionss)
  }
  
  const submit = async (event) => {
    event.preventDefault()
    if(password===repassword){
      //const saltRounds = 17
      var salt = bcryptjs.genSaltSync(12)
      const passwordhash = await bcryptjs.hash(password, salt)
      console.log(passwordhash)
     /* const user = new User({
        username: body.username,
        name: body.name,
        passwordHash,
      })
    
      const savedUser = await user.save()
    
      response.json(savedUser)
      */
      //const passwordhash = password
      createUser({
        variables: { name, email, passwordhash, address, postnumber, postoffice, datetime, phone, activated:false, type, acceptedtermsofuse, code  }
      })
      //sectionid: sectionid.length > 0 ? sectionid : 'tyhjä'
      setDatetime('')
      setName('')
      setEmail('')
      setPassword('')
      setAddress('')
      setPostnumber('')
      setPostoffice('')
      setPhone('')
      setRepassword('')
      setType('')
      setActivated('')
      setBorn('')
      setGender('')
      setMunicipality('')
      setCountry('')
      setSubmitted(true)
      //setSectionid(sectionss)
    }else{
      alert('Salasanat eivät täsmää')
    }
  }
  const stylea = {
    background: 'white',
    opacity: 0.9
  }
  //useEffect(() => {
   // setSectionid(sectionss)
  //}, [])
  if(submitted){
        return(<div>
            <h2>{t('headers.addcommentorinformation')}</h2>
            {t('common.formok')}
            <br />
        </div>
        )
      }else{
        return (
      <div>
        <h2>{t('headers.adduser')}</h2>

        <form onSubmit={submit}>
        {t('common.name')} <TextField
            value={name}
            onChange={({ target }) => uusi(target.value)}
          /><br /><br />
          {t('common.email')}  <TextField
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          /><br /><br />
          {t('common.password')} <TextField type='password'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          /><br /><br />
          {t('common.repassword')}  <TextField type='password'
            value={repassword}
            onChange={({ target }) => setRepassword(target.value)}
          /><br /><br />
          {t('common.address')}  <TextField
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          /><br /><br />
          {t('common.postnumber')}  <TextField
            value={postnumber}
            onChange={({ target }) => setPostnumber(target.value)}
          /><br /><br />
          {t('common.postoffice')}  <TextField
            value={postoffice}
            onChange={({ target }) => setPostoffice(target.value)}
          /><br /><br />
          {t('common.born')}  <TextField
            value={born}
            onChange={({ target }) => setBorn(target.value)}
          /><br /><br />
          {t('common.gender')}  <TextField
            value={gender}
            onChange={({ target }) => setGender(target.value)}
          /><br /><br />
          {t('common.municipality')}  <TextField
            value={municipality}
            onChange={({ target }) => setMunicipality(target.value)}
          /><br /><br />
          {t('common.country')}  <TextField
            value={country}
            onChange={({ target }) => setCountry(target.value)}
          /><br /><br />
          {t('common.phone')}  <TextField
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /><br /><br />
        {t('common.active')}  <TextField
            value={activated}
            onChange={({ target }) => setActivated(target.value)}
          /><br /><br />
          {t('common.usertype')}  <TextField
            value={type}
            onChange={({ target }) => setType(target.value)}
          /><br /><br />
          <div align='center'>
                <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
              <br /><br />
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
              disabled={(!verified)}
              executing={executing}
              submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
            <br /><br />
      </form>
      </div>
    )
  }
}

export default UserForm