import React, { useState } from 'react'

import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const Info = ({ createComment }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [newComment, setNewComment] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const handleChange = (event) => {
    setNewComment(event.target.value)
  }

  const addComment = (event) => {
    event.preventDefault()
    /*createComment({
      content: newComment,
      important: false,
    })
    */
    setNewComment('')
    setSubmitted(true)
  }

  if(submitted){
        return(<div>
            <h2>{t('headers.addcommentorinformation')}</h2>
            {t('common.formok')}
            <br />
        </div>
        )
      }else{
        return (
      <div>
        <h2>{t('common.info')}</h2> 
        <p>{t('common.takecontact')}</p>
        <form onSubmit={addComment}>
        {t('common.name')} <TextField /><br /><br />
        {t('common.phone')} <TextField /><br /><br />
        {t('common.email')} <TextField /><br/><br />
        {t('common.thing')} <TextField
            value={newComment}
            onChange={handleChange}
          /><br /><br />
          <div align='center'>
                <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
              <br /><br />
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
              disabled={(!verified)}
              executing={executing}
              submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
            <br /><br />
        </form>
        <p>{t('common.usage')}</p>
        <p>{t('common.contactinfo')}</p> 
      </div>
    )
  }
}
export default Info