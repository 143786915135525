
import React, { useState }  from 'react'
import { useMutation } from '@apollo/client'
import { ALL_SECTIONS,DELETE_SECTION,LOCK_SECTION} from '../queries'
import {
  Link,
  useParams
} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {
  Button
} from '@material-ui/core'


const Sectionsadmin = ({sections, meetings, setError}) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  let agenda=''
  agenda = useParams().id
  let meet=[]
  if(agenda){
    meet= meetings.filter(function(el) {
      return el.id===agenda
    })
  }
  const [ lockSection ] = useMutation(LOCK_SECTION, {
    refetchQueries: [  {query: ALL_SECTIONS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  const [ deleteSection ] = useMutation(DELETE_SECTION, {
    refetchQueries: [  {query: ALL_SECTIONS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const setLocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    lockSection({
      variables: { id:event, locked:true }
    })
    //muutetaan activated !actived edituser
  }
  const setUnlocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    
    lockSection({
      variables: { id:event, locked:false }
    })
    //muutetaan activated !actived edituser
  }

  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteSection({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }

  //console.log(meet)
  if(agenda){
      let valittu = sections.filter(function(el) {
        return el.agendaid===agenda
      })
      return(
      <div width='100%'>
        <table width='100%' align='left'><tr><td align='left' width='50%'>
          {meet.map(p => (
            <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.meetingtext}</h3>
            ))}
            <ul>
                  {valittu.map(section => (
                    <li>
                        <Link to={`/sections/${section.id}`}>{section.header}</Link>
                      </li>
                  ))
          }
          </ul>
          </td></tr></table>
      </div>
    )
    }else{
      return(
        <div width='100%'>
         {meet.map(p => (
          <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.meetingtext}</h3>
          ))}
          
                {sections.map(section => (
                <div>
                  <Link to={`/sections/${section.id}`}>{section.header}</Link><br />
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setUnlocked(section.id)}>{t('common.unlock')}</Button>
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setLocked(section.id)}>{t('common.lock')}</Button>
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa asian?')){setDelete(section.id)}}}>{t('common.delete')}</Button>
                </div> 
                ))}   
                
        </div>
      )
    }
}

export default Sectionsadmin