import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_INITIATIVES, CREATE_INITIATIVE,  } from '../queries'

import {
  TextField,
  Button
} from '@material-ui/core'


import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/
import ReCAPTCHA from "react-google-recaptcha"

const InitiativeForm = ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [initiativetext, setText] = useState('')
  //const [userid, setUser] = useState('werewrwe')
  const [organid, setOrganid] = useState('')
  //const [initiativedatetime, setDatetime] = useState('')
  const [initiativeinfo, setInfo] = useState('')
  const [initiativeaccepted, setAccepted] = useState('')
  const [active, setActive] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)


  const [ createInitiative ] = useMutation(CREATE_INITIATIVE, {
    refetchQueries: [  {query: ALL_INITIATIVES} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

/*
  const handleChange = (event) => {
    setNewComment(event.target.value)
  }

  const addComment = (event) => {
    event.preventDefault()
    createComment({
      content: newComment,
      important: false,
    })

    setNewComment('')
  }
  */
 /* const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setText(event)
  }*/
  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const submit = async (event) => {
    event.preventDefault()
    const aika = Date.now()
    const pvm = new Date(aika)
    //setDatetime(pvm.toString())
    createInitiative({
      variables: { organid, initiativetext, initiativedatetime:pvm.toString(), initiativeinfo, initiativeaccepted, active}
    })
    setText('')
    //setDatetime('')
    setOrganid('')
    //setUser('')
    setActive('')
    setAccepted('')
    setInfo('')
    setSubmitted(true)
  }


  if(submitted){
    return(<div>
        <h2>{t('headers.addinitiative')}</h2>
        {t('common.formok')}
        <br />
    </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.addinitiative')}</h2>

        <form onSubmit={submit}>
        {t('common.initiative')} <TextField
            value={initiativetext}
            onChange={({ target }) => setText(target.value)}
          /><br /><br />
          {t('common.organ')} <TextField
            value={organid}
            onChange={({ target }) => setOrganid(target.value)}
          /><br /><br />
          {t('common.active')} <TextField
            value={active}
            onChange={({ target }) => setActive(target.value)}
          /><br /><br />
          {t('common.accepted')} <TextField
            value={initiativeaccepted}
            onChange={({ target }) => setAccepted(target.value)}
          /><br /><br />
          {t('common.info')}<TextField
            value={initiativeinfo}
            onChange={({ target }) => setInfo(target.value)}
          /><br /><br />
          <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <br /><br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
          <br /><br />
          </form>
      </div>
    )
  }
}

export default InitiativeForm