import React, { useState, useEffect} from 'react'
import { useMutation } from '@apollo/client'
import {
  useParams
} from "react-router-dom"
import { ALL_PROPOSALS, CREATE_PROPOSAL } from '../queries'
import {
  TextField,
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const ProposalForm = ({ setError }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [proposaltext, setText] = useState('')
  const [proposaldatetime, setDate] = useState('')
  const [sectionid, setSection] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)

  const [ createProposal ] = useMutation(CREATE_PROPOSAL, {
    refetchQueries: [  {query: ALL_PROPOSALS} ],
    onError: (error) => {
      setError('virhe')
      //error.graphQLErrors[0].message
      console.log(error)
    }
  })
  const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDate(pvm.toString())
    setText(event)
  }
  let sectionss=''
  sectionss = useParams().id
  
  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const submit = async (event) => {
    event.preventDefault()

    createProposal({
      variables: { proposaltext, proposaldatetime,  sectionid }
    })

    setText('')
    setDate('')
    setSection(sectionss)
    setSubmitted(true)
  }
  useEffect(() => {
    setSection(sectionss)
  }, [sectionss])
  if(submitted){
        return(<div>
            <h2>{t('headers.addproposal')}</h2>
            {t('common.formok')}
            <br />
        </div>
        )
      }else{
        return (
      <div>
        <h2>{t('headers.addproposal')}</h2>
        <form onSubmit={submit}>
          <div>
          {t('common.proposal')} <TextField
              value={proposaltext}
              onChange={({ target }) => uusi(target.value)}
            />
          </div>
          <br /><br />
          <div align='center'>
                <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
            </div>
              <br /><br />
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
              disabled={(!verified)}
              executing={executing}
              submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
            <br /><br />
            </form>
      </div>
    )
  }
}

export default ProposalForm