import React, {  useState } from "react"
import {
  useParams
} from "react-router-dom"
import {useTranslation} from "react-i18next"
import { object } from "prop-types"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/

const Section = ({sections}) => {
  const asia = useParams().id
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [liite, setLiite] = useState('')
  let valittu = sections.filter(function(el) {
    return el.id===asia
  })

  
 let valitut={casetext: 'ladataan...', header:'ladataan', preparer:'ladataan...', caseid:'ladataan...',proposalforadecision:'ladataan...',x:'ladataan...',y:'ladataan...',z:'ladataan...',address:'ladataan...',postnumber:'ladataan...',postoffice:'ladataan...',country:'ladataan...',attachments:'{"liitteet":"ladataan..."}',sectionmunicipality:'ladataan...',agendaid:'ladataan...'}
 if(valittu[0]){
  valitut = valittu[0]
  console.log(valitut)
 }
 console.log(valitut)
  /*let valitut={}
  valittu[0]
  console.log(valittu[0])
  */
  let liit = valitut.attachments.split('|||||')
  //try {
    let a = []
    a=JSON.parse(JSON.stringify(valitut.attachments))
   // setLiite(a)
  //} catch(e) {
     // alert(e)
  //}
  console.log(liit)
  let liitarr=[]

  for(let i=0;i<liit.length;i++){
    let liityhd = liit[i].split('<<<<<<')
    liitarr.push(liityhd)
  }

  console.log(liitarr)

  return(
    <div width='100%'>
    <table width='100%' align='left'><tr><td align='left' width='50%'>
    <h2>{valitut.header}</h2><br />
    {valitut.casetext}<br /><br />
    <b>{t('common.preparer')}</b> {valitut.preparer}<br /><br />
    <b>{t('common.sectionnumber')}</b> {valitut.caseid}<br /><br />
    <b>{t('common.proposal')}</b> {valitut.proposalforadecision}<br /><br />
    
    <b>{t('common.attachments')}</b><br />
    {liitarr.map(p =>
        <>
          <a href={p[0]}>{p[1]}</a><br />
        </>
      )}
      <br />
    <b>{t('common.municipal')}</b> {valitut.sectionmunicipality}<br /><br />
    <b>{t('common.agenda')}</b> {valitut.agendaid}<br /><br />
    </td></tr></table>
  </div>
)
}

export default Section