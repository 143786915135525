import React, { Component, useState, useEffect } from 'react'
import {
  Link
} from "react-router-dom"
import {
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { DELETE_MINUTES, ALL_MINUTES } from '../queries'


const Minutesadmin = ({ setError, minutes, toggleImportance }) => {
  const [t, i18n] = useTranslation('common')
  const [del, setDelete]= useState('')
  const [ deleteMinutes ] = useMutation(DELETE_MINUTES, {
    refetchQueries: [  {query: ALL_MINUTES}],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })  
  console.log(minutes)
  
  useEffect(() => {
    if(del!==''){
      deleteMinutes({
        variables: { id: del }
      })

      setDelete('')
    }
  }, [del, deleteMinutes])

  if(minutes){
    let minutesfileheader="id;datetime;text;organid;municipality;url\n"
    let minutesfiletext=minutes.map(meet =>(String(meet.id) + ";" + String(meet.minutesdatetime) + ";" + String(meet.minutestext) + ";" + String(meet.organid) + ";" + String(meet.minutesmunicipality) + ";"  + String(meet.minutesurl) + "\n"
      ))
      //meet.active & ";" & meet.minutesdatetime & ";" & meet.minutestext & ";" & meet.organid & ";" & meet.minutesmunicipality & ";" &meet.minutesurl& "\n"
    let minutesfile=minutesfileheader.concat(minutesfiletext)

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:attachment/text,' + encodeURI(minutesfile.toString());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'minutes.csv';

    return (
      <>
       <li>
          <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {hiddenElement.click();}}>Lataa pöytäkirjat</Button>
          <br />
        </li>
        <hr />
        <ul>
              {minutes.map(meet => (
                <li>
                    <Link to={`/minutes/${meet.id}`}>{meet.organid} </Link> {meet.minutestext} {meet.minutesmunicipality}
                    <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa kokouksen?')){setDelete(meet.id)}}}>{t('common.delete')}</Button>
                </li>
                
              ))}
              
              
        </ul>

      </>
    )
  }else{
    return (
      <li className='meeting'>
         Ei kokouksia
      </li>
    )
  }
}

export default Minutesadmin