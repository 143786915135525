import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_ORGANS, CREATE_ORGAN } from '../queries'
import {
  TextField,
  Button,
Select,
MenuItem
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const stylea = {
  background: 'white',
  opacity: 0.9
}

const OrganForm = ({ setError }) => {
  const [organname, setName] = useState('')
  const [organshort, setShort] = useState('')
  const [organurl, setUrl] = useState('')
  const [organmunicipality, setMunicipality] = useState('')
  const [system, setSystem] = useState('')
  const [domain, setDomain] = useState('')
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)


  const [ createOrgam ] = useMutation(CREATE_ORGAN, {
    refetchQueries: [  {query: ALL_ORGANS} ],
    onError: (error) => {
      setError('virhe')
      //error.graphQLErrors[0].message
      console.log(error)
    }
  })

  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  const submit = async (event) => {
    event.preventDefault()

    createOrgam({
      variables: { organname, organshort, organmunicipality, organurl, system, domain }
    })

    setName('')
    setShort('')
    setMunicipality('')
    setUrl('')
    setSubmitted(true)
  }

  if(submitted){
    return(<div>
        <h2>{t('headers.addorgan')}</h2>
        {t('common.formok')}
        <br />
    </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.addorgan')}</h2>
        <form onSubmit={submit}>
          <div>
          {t('common.name')} <TextField
              value={organname}
              onChange={({ target }) => setName(target.value)}
            />
          </div><br />
          <div>
            {t('common.abbreviation')}<TextField
              value={organshort}
              onChange={({ target }) => setShort(target.value)}
            />
          </div><br />
          <div>
            {t('common.municipal')} <TextField
              value={organmunicipality}
              onChange={({ target }) => setMunicipality(target.value)}
            />
          </div><br />
          <div>
            Url <TextField
              value={organurl}
              onChange={({ target }) => setUrl(target.value)}
            />
          </div><br />
          <div>
            Domain <TextField
              value={domain}
              onChange={({ target }) => setDomain(target.value)}
            />
          </div><br />
          <div>
            System
        <Select labelId="gender" id="gender" value={system} onChange={({ target }) => setSystem(target.value)}>
            <MenuItem value={'ei valitt'}>ei valittu</MenuItem>
            <MenuItem value={'CaseM'}>CaseM</MenuItem>
            <MenuItem value={'Dynasty'}>Dynasty</MenuItem>
            <MenuItem value={'Tweb'}>Tweb</MenuItem>
          </Select>
          </div><br />
          <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <br /><br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.save')}</Button>
          <br /><br />
          </form>
      </div>
    )
    }
}

export default OrganForm