import React, { Component, useState, useEffect } from "react"
import {
  Link
} from "react-router-dom"
import {
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"

const Meetings = ({ meeting, toggleImportance }) => {
  const [old, setOld] = useState(false)
  const [t, i18n] = useTranslation('common')
  const [meetold, setMeetold] = useState(meeting)
  const aika = Date.now()
  const pvm = new Date(aika)



  useEffect(() => {
    let mee = meeting.filter(meet => meet.meetingdatetime > pvm)
    console.log(mee)
    if(old===false){
      setMeetold(mee)
    }else{
      setMeetold(meeting)
    }
  }, [old,meeting])

  console.log(meeting)
  //setMeetold(meeting)
  console.log(old)
  console.log(meetold)
  if(meetold){
    return (
      <>
      <ul>
      <Button variant="contained" size='normal' color="inherit" onClick={({ target }) => setOld(!old)}>
        {t('common.showold')}
       </Button><br /><br />
            {meetold.map(meet => (
              <li>
                  <Link to={`/agendas/${meet.id}`}>{meet.organid} </Link> {meet.meetingtext} {meet.meetingmunicipality}
               </li>
            ))}
      </ul>
      </>
    )
  }else{
    return (
      <li className='meeting'>
         Ei kokouksia
      </li>
    )
  }
}

export default Meetings