import React from 'react'


import {
  Link,
  useParams
} from "react-router-dom"
import {useTranslation} from "react-i18next"



const Minuts = ({minute, minutes}) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  let agenda=''
  agenda = useParams().id
  let meet=[]
  if(agenda){
    meet= minutes.filter(function(el) {
      return el.id===agenda
    })
  }
  
  //console.log(meet)
  if(agenda){
      let valittu = minute.filter(function(el) {
        return el.minutesid===agenda
      })
      return(
      <div width='100%'>
        <table width='100%' align='left'><tr><td align='left' width='50%'>
          {meet.map(p => (
            <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.minutestext}</h3>
            ))}
            <ul>
                  {valittu.map(minute => (
                    <li>
                        <Link to={`/minute/${minute.id}`}>{minute.header}</Link>
                      </li>
                  ))
          }
          </ul>
          </td></tr></table>
      </div>
    )
    }else{
      return(
        <div width='100%'>
          <table width='100%' align='left'><tr><td align='left' width='50%'>
          {meet.map(p => (
          <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.header}</h3>
          ))}
          <ul>
            
                {minute.map(minute => (
                  <li>
                      <Link to={`/minute/${minute.id}`}>{minute.header}</Link>
                 </li>
                ))}
          </ul> 
          </td></tr></table>     
        </div>
      )
    }
}

export default Minuts