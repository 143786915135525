import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import { ALL_LINKS, CREATE_LINK } from '../queries'
import {
  useParams
} from "react-router-dom"

import {
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"

const LinkForm = ({ setError }) => {
  const [initiativeid, setInitiative] = useState('')
  const [linktext, setText] = useState('')
  const [linktype, setType] = useState('')
  const [userid, setUser] = useState('')
  const [linkdatetime, setDatetime] = useState('')
  const [sectionid, setSectionid] = useState('')
  const [proposalid, setProposalid] = useState('')
  const [commentid, setCommentid] = useState('')
  const [linkref, setLinkref] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)

  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')

  const [ createLink ] = useMutation(CREATE_LINK, {
    refetchQueries: [  {query: ALL_LINKS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

/*
  const handleChange = (event) => {
    setNewComment(event.target.value)
  }

  const addComment = (event) => {
    event.preventDefault()
    createComment({
      content: newComment,
      important: false,
    })

    setNewComment('')
  }
  */
 let sectionss=''
  sectionss = useParams().id
  const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    setDatetime(pvm.toString())
    setText(event)
    setSectionid(sectionss)
  }
  function onChange(value) {
    console.log("Captcha value:", value);
    setTokeni(value)
    setVerified(!verified)
  }

  
  const submit = async (event) => {
    event.preventDefault()
    
    createLink({
      variables: { linkdatetime, linktext, commentid, proposalid, linktype, initiativeid, linkref, sectionid: sectionid.length > 0 ? sectionid : 'tyhjä' }
    })

    setDatetime('rwerwer')
    setText('')
    setSectionid(sectionss)
    setSubmitted(true)
  }
  useEffect(() => {
    setSectionid(sectionss)
  }, [])
  if(submitted){
    return(<div>
        <h2>{t('headers.addlink')}</h2>
        {t('common.formok')}
        <br />
    </div>
    )
  }else{
    return (
      <div>
        <h2>{t('headers.addlink')}</h2>

        <form onSubmit={submit}>
        {t('common.linktext')} <TextField
            value={linktext}
            onChange={({ target }) => uusi(target.value)}
          /><br />
          {t('common.proposal')}: <TextField
            value={proposalid}
            onChange={({ target }) => setProposalid(target.value)}
          /><br />
          {t('common.initiative')}: <TextField
            value={initiativeid}
            onChange={({ target }) => setInitiative(target.value)}
          /><br />
          {t('common.user')}: <TextField
            value={userid}
            onChange={({ target }) => setUser(target.value)}
          /><br />
          {t('common.comment')}: <TextField
            value={commentid}
            onChange={({ target }) => setCommentid(target.value)}
          /><br />
          {t('common.linkref')}: <TextField
            value={linkref}
            onChange={({ target }) => setLinkref(target.value)}
          /><br />
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('common.linktype')}</FormLabel>
            <RadioGroup aria-label="linktype" name="linktype" value={linktype} onChange={({ target }) => setType(target.value)}>
              <FormControlLabel value='1' control={<Radio />} label={t('common.site')} />
              <FormControlLabel value='2' control={<Radio />} label={t('common.picture')} />
              <FormControlLabel value='3' control={<Radio />} label={t('common.video')} />
              <FormControlLabel value='4' control={<Radio />} label={t('common.audio')} />
              <FormControlLabel value='5' control={<Radio />} label={t('common.document')} />
              <FormControlLabel value='6' control={<Radio />} label={t('common.other')} />
              <FormControlLabel value='99' disabled control={<Radio />} label={t('common.notdefined')} />
            </RadioGroup>
          </FormControl>
          <br />
          <div align='center'>
              <ReCAPTCHA sitekey="6Lf6wLIaAAAAAORH1jwBkpv1NzwvaBYa9mpn39GP" onChange={onChange} />
          </div>
            <br /><br />
            <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} type='submit'
            disabled={(!verified)}
            executing={executing}
            submitted={submitted} >{submitted ? t('common.done') : executing ? 'Verifying' : t('common.send')}</Button>
          <br /><br />
          </form>
      </div>
    )
  }
}

export default LinkForm