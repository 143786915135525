import React, { Component, useState, useEffect } from 'react'
import {
  Link
} from "react-router-dom"
import {
  Button
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { DELETE_MEETING, ALL_MEETINGS } from '../queries'


const Meetingsadmin = ({ setError, meeting, toggleImportance }) => {
  const [t, i18n] = useTranslation('common')
  const [del, setDelete]= useState('')
  const [ deleteMeeting ] = useMutation(DELETE_MEETING, {
    refetchQueries: [  {query: ALL_MEETINGS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })  
  console.log(meeting)
  
  useEffect(() => {
    if(del!==''){
      deleteMeeting({
        variables: { id: del }
      })

      setDelete('')
    }
  }, [del, deleteMeeting])

  if(meeting){
    return (
      
      <ul>
            {meeting.map(meet => (
              <li>
                  <Link to={`/agendas/${meet.id}`}>{meet.organid} </Link> {meet.meetingtext} {meet.meetingmunicipality}
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa kokouksen?')){setDelete(meet.id)}}}>{t('common.delete')}</Button>

               </li>
            ))}
      </ul>
    )
  }else{
    return (
      <li className='meeting'>
         Ei kokouksia
      </li>
    )
  }
}

export default Meetingsadmin