import React, { useState }  from 'react'
import { useMutation } from '@apollo/client'
import {
  useParams
} from "react-router-dom"

import {
  Button

} from '@material-ui/core'


import { ALL_LINKS,DELETE_LINK,LOCK_LINK} from '../queries'
import {useTranslation} from "react-i18next"
/*
import {useTranslation} from "react-i18next"
const [t, i18n] = useTranslation('common')
{t('headers.agendas')}

*/

const Linksadmin = ({ links, sections, setError, votes }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  // eslint-disable-next-line
  const [userid, setUserid] = useState('')
 
  let sectionss=''
  sectionss = useParams().id
  let valittu = sections
  if(sections){
    valittu = sections.filter(function(el) {
      return el.id===sectionss
    })
  }
  
  console.log(valittu)
  //console.log(comment.data)

  
  let valitut = links
  if(sectionss){
    valitut = links.filter(function(el) {
      return el.sectionid===sectionss
    })
  }

  let aanet= votes
  if(sectionss){
    aanet=votes.filter(function(aa) {
      return aa.sectionid===sectionss
    } )
  }
  let aanetasiat= aanet.map(p => p.linkid)
  let maarat = Object.values(aanet.reduce((obj, { linkid }) => {
    if (obj[linkid] === undefined)
       obj[linkid] = { linkid: linkid, occurrences: 1 };
    else
       obj[linkid].occurrences++;
    return obj;
 }, {}));
 let maara = maarat.map(o => {
  const sisalto = {};

  sisalto[o.linkid] = o.occurrences

  return sisalto})

  //Tähän uuden parametrin count lisääminen valitut arrayhin sen arvoksi maarat arraystä saman commentid:n parametrin occurrences arvo
  let uus= valitut.map((item) => 
      Object.assign({}, item, {maara:aanetasiat.filter(function(aa) {
        if(aa === null){
          aa=[]
        }
        return aa===item.id}).length
      }))
  /*for(let i=0;i<valitut.length;i++){
    valitut[i].maara=0
    Object.preventExtensions(valitut)
  }*/
  //const maarat = aanetasiat => aanetasiat.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {})
  console.log(uus)
  console.log(maara)
  const [ lockLink ] = useMutation(LOCK_LINK, {
    refetchQueries: [  {query: ALL_LINKS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  const [ deleteLink ] = useMutation(DELETE_LINK, {
    refetchQueries: [  {query: ALL_LINKS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const setLocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    lockLink({
      variables: { id:event, locked:true }
    })
    //muutetaan activated !actived edituser
  }
  const setUnlocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    
    lockLink({
      variables: { id:event, locked:false }
    })
    //muutetaan activated !actived edituser
  }

  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteLink({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }

  if(links){
    return (
      <div>
        <h3>{t('headers.links')}</h3>
            {uus.map(lin => (
              <div>
                <a href={lin.linkref} target='0'>{lin.linktext}</a><br /> 
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setUnlocked(lin.id)}>{t('common.unlock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setLocked(lin.id)}>{t('common.lock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa linkin?')){setDelete(lin.id)}}}>{t('common.delete')}</Button>
                </div>
            ))}
      </div>
    )
  }else{
    return (
      <li className='link'>
         {t('common.nolinks')}
      </li>
    )
  }
}

export default Linksadmin