
import React, { useState }  from 'react'
import { useMutation } from '@apollo/client'
import { ALL_MINUT,DELETE_MINUT,LOCK_MINUT} from '../queries'
import {
  Link,
  useParams
} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {
  Button
} from '@material-ui/core'


const Minutadmin = ({minute, minutes, setError}) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  let agenda=''
  agenda = useParams().id
  let meet=[]
  if(agenda){
    meet= minutes.filter(function(el) {
      return el.id===agenda
    })
  }
  const [ lockMinut ] = useMutation(LOCK_MINUT, {
    refetchQueries: [  {query: ALL_MINUT} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  const [ deleteMinut ] = useMutation(DELETE_MINUT, {
    refetchQueries: [  {query: ALL_MINUT} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const setLocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    lockMinut({
      variables: { id:event, locked:true }
    })
    //muutetaan activated !actived edituser
  }
  const setUnlocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    
    lockMinut({
      variables: { id:event, locked:false }
    })
    //muutetaan activated !actived edituser
  }

  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteMinut({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }

  //console.log(meet)
  if(agenda){
      let valittu = minute.filter(function(el) {
        return el.agendaid===agenda
      })

      let minutefile=minute.map(meet =>(String(meet.id) + ";" + String(meet.caseid) + ";" + String(meet.casetext) + ";" + String(meet.country) + ";" + String(meet.minutmunicipality) + ";"  + String(meet.minuturl)  + ";"  + String(meet.decision) + ";"  + String(meet.header) + ";"  + String(meet.minutesid) + ";"  + String(meet.preparer) + ";"  + String(meet.proposalforadecision) + "\n"
      ))

      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:attachment/text,' + encodeURI(minutefile.toString());
      hiddenElement.target = '_blank';
      hiddenElement.download = 'minute.csv';
      return(
      <div width='100%'>
        <table width='100%' align='left'><tr><td align='left' width='50%'>
          {meet.map(p => (
            <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.minutestext}</h3>
            ))}
            <ul>
                  {valittu.map(minute => (
                    <li>
                        <Link to={`/minute/${minute.id}`}>{minute.minutestext}</Link>
                      </li>
                  ))
          }
          </ul>
          <li>
              <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {hiddenElement.click();}}>Lataa pöytäkirjojen asiat</Button>
          </li>
          </td></tr></table>
      </div>
    )
    }else{
      let minutefileheader="id;caseid;casetext;country;municipality;url;decision;header;minutesid;preparer;proposal\n"
      
      let minutefiletext = minute.map(meet =>(String(meet.id) + ";" + String(meet.caseid).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + ";" + String(meet.casetext).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + ";" + String(meet.country) + ";" + String(meet.minutmunicipality) + ";"  + String(meet.minuturl)  + ";"  + String(meet.decision).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + ";"  + String(meet.header).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + ";"  + String(meet.minutesid) + ";"  + String(meet.preparer).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + ";"  + String(meet.proposalforadecision).replaceAll('  ','').replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g, ' ').trim() + "\n"
      ))
      let minutefile =minutefileheader.concat(minutefiletext)
      let hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:attachment/text,' + encodeURI(minutefile)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'minute.csv'
      return(
        <div width='100%'>
          <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {hiddenElement.click();}}>Lataa pöytäkirjojen asiat</Button><br /><br />
          <hr />
         {meet.map(p => (
          <h3>{t('headers.sectionsinmeeting')} {p.organid} {p.header}</h3>
          ))}
          
                {minute.map(minute => (
                <div>
                  <Link to={`/minute/${minute.id}`}>{minute.header}</Link><br />
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setUnlocked(minute.id)}>{t('common.unlock')}</Button>
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setLocked(minute.id)}>{t('common.lock')}</Button>
                  <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa asian?')){setDelete(minute.id)}}}>{t('common.delete')}</Button>
                </div> 
                ))}   
                <br /><br />
       </div>
      )
    }
}

export default Minutadmin