import React, { useState }  from 'react'
import {useTranslation} from "react-i18next"
import {
  TextField,
  Button
} from '@material-ui/core'
import { set } from 'lodash-es'
import { useMutation } from '@apollo/client'
import { ALL_USERS, ACTIVATE_USER,LOCK_USER, DELETE_USER } from '../queries'


const Users = ({ users, sections, setError, votes }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [actived, setActived] = useState('')
  const [id, setId] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [postoffice, setPostoffice] = useState('')
  const [phone, setPhone] = useState('')
  const [passwordhash, setPassword] = useState('')
  const [activated, setActivated] = useState(true)
  const [datetime, setDatetime] = useState('')
  const [type, setType] = useState('')
  const [gender, setGender] = useState('')
  const [born, setBorn] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [acceptedtermsofuse, setAccepted] = useState('Sat Feb 27 2021 09:21:48 GMT+0200 (Itä-Euroopan normaaliaika)')
  const [country, setCountry] = useState('')
  const [token, setTokeni] = useState('')
  const [verified, setVerified] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [executing, setExecuting] = useState(false)



  const [ activateUser ] = useMutation(ACTIVATE_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })
  const [ lockUser ] = useMutation(LOCK_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  const [ deleteUser ] = useMutation(DELETE_USER, {
    refetchQueries: [  {query: ALL_USERS} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  })

  
  //const [userid, setUserid] = useState('')  

  /*const [ createVoting ] = useMutation(CREATE_VOTING, {
    refetchQueries: [  {query: ALL_VOTES} ],
    onError: (error) => {
      
      //setError(error.graphQLErrors[0].message)
      setError('virhe')
    },
    /*update: (store, response) => {
      updateCacheWith(response.data.addComment)
    }*/
  //})
 /* const uusi = async (event) => {
    console.log(event)
    const aika = Date.now()
    const pvm = new Date(aika)
    createVoting({
      variables: { proposalid:event, userid: userid.length > 0 ? userid : 'tyhjä', votedatetime:pvm.toString(), sectionid:sectionss }
     })
 
  }
*/

  //let sectionss=1
  /*sectionss = useParams().id
  let valittu = sections.filter(function(el) {
    return el.id===sectionss
  })*/
 /* console.log(users)
  let valitut = users.filter(function(el) {
    return el.sectionid===sectionss
  })
  */
  //let users =[]
  console.log(users)
  //setActived(users.activated)
  const setActive = async (event) => {
    //event.preventDefault()
    //console.log(event)
    setId(users.id)
    setEmail(event)
    setActived(users.activated)
    activateUser({
      variables: { email, activated, acceptedtermsofuse }
    })
    //muutetaan activated !actived edituser
  }
  const setLocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    setId(users.id)
    setEmail(event)
    setActived(users.activated)
    lockUser({
      variables: { email:event, locked:true }
    })
    //muutetaan activated !actived edituser
  }
  const setUnlocked = async (event) => {
    //event.preventDefault()
    //console.log(event)
    setId(users.id)
    setEmail(event)
    setActived(users.activated)
    lockUser({
      variables: { email:event, locked:false }
    })
    //muutetaan activated !actived edituser
  }

  const setDelete = async (event) => {
    //event.preventDefault()
    //console.log(event)
    deleteUser({
      variables: { id:event }
    })
    //muutetaan activated !actived edituser
  }

  if(users){
    return (
      <div>
        <h3>{t('headers.users')}</h3>
        {users.map(prop => (
            <div>
              <p><b>{t('common.name')}</b> {prop.name}<br />
                <b>{t('common.email')}</b> {prop.email}<br />
                <b>{t('common.address')}</b> {prop.address} <br />
                <b>{t('common.postnumber')}</b> {prop.postnumber}<br />
                <b>{t('common.postoffice')}</b> {prop.postoffice}<br />
                <b>{t('common.phone')}</b> {prop.phone}<br />
                <b>{t('common.active')}</b> {prop.activated}<br />
                <b>{t('common.lock')}</b> {prop.locked}<br />
                <b>{t('common.usertype')}</b> {prop.type}<br />
                <b>{t('common.born')}</b> {prop.born}<br />
                <b>{t('common.gender')}</b> {prop.gender}<br />
                <b>{t('common.municipality')}</b> {prop.municipality}<br />
                <b>{t('common.country')}</b> {prop.country}<br />
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setUnlocked(prop.email)}>{t('common.unlock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setLocked(prop.email)}>{t('common.lock')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => setActive(prop.email)}>{t('common.active')}</Button>
                <Button style={{backgroundColor: "#07575B", color:"#C4DFE6",fontSize: "14px" }} size='small' type='submit' onClick={({ target }) => {if(window.confirm('Haluatko varmasti poistaa tunnuksen?')){setDelete(prop.id)}}}>{t('common.delete')}</Button>
            </p>
            </div>   
            ))}
      </div>
    )
  }else{
    return (
      <li className='userit'>
         {t('common.nousers')}
      </li>
    )
  }
}

export default Users